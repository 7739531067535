@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

:root {
  --blue: #5C7AFF;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #444;
}

/* avoid iphone auto zoom on input */
@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
